var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mt-2"
  }, [_vm.isError ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Opps .. Something is wrong ")])], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Here to Search",
      "type": "search"
    },
    on: {
      "input": _vm.onSearch,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onSearch(_vm.author_search);
      }
    },
    model: {
      value: _vm.author_search,
      callback: function ($$v) {
        _vm.author_search = $$v;
      },
      expression: "author_search"
    }
  }), _vm.typing ? _c('small', [_c('em', [_vm._v(" typing ... ")])]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "row justify-content-end m-1"
  }, [_c('span', {
    staticClass: "pr-2"
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "to": _vm.AuthorCreatePath
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  })])], 1), _c('span', [_c('button', {
    staticClass: "btn btn-sm btn-dark",
    on: {
      "click": _vm.reloadPage
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh"
  })])]), _c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm._f("toLocalStringID")(_vm.totalRows)))])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "xl",
      "hover": "",
      "items": _vm.items,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "300px"
          }
        }, [_c('router-link', {
          attrs: {
            "to": Object.assign({}, _vm.AuthorUpdatePath, {
              params: {
                id: row.item.id
              }
            })
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])], 1)];
      }
    }, {
      key: "cell(slug)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s(row.item.slug) + " ")])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  }), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.onClickPage
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }