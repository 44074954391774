<template>
  <b-card class="mt-2">
    <b-row v-if="isError" class="justify-content-center mt-2">
      <b-col lg="10">
        <b-alert variant="danger" show dismissible>
          Opps .. Something is wrong
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-input placeholder="Type Here to Search" type="search" v-model="author_search" @input="onSearch"
          v-on:keyup.enter="onSearch(author_search)"></b-form-input>
        <small v-if="typing"> <em> typing ... </em> </small>
      </b-col>
    </b-row>
    <div class="row justify-content-end m-1">
      <span class="pr-2">
        <router-link class="btn btn-sm btn-dark" :to="AuthorCreatePath">
          <i class="fa fa-plus"></i>
        </router-link>
      </span>
      <span>
        <button class="btn btn-sm btn-dark" @click="reloadPage">
          <i class="fa fa-refresh"></i>
        </button>
      </span>
      <label for="" class="col-sm-auto">Count </label>
      <strong class="col-sm-auto">{{ totalRows | toLocalStringID }}</strong>
    </div>
    <b-table sticky-header="500px" responsive="xl" hover :items="items" :busy="isLoading" :isLoading="isLoading"
      :fields="fields" show-empty>
      <template #cell(No)="row">
        {{ (currentPage - 1) * perPage + (row.index + 1) }}
      </template>
      <template #cell(name)="row">
        <div style="width: 300px;">
          <router-link :to="{ ...AuthorUpdatePath, params: { id: row.item.id } }">
            {{ row.item.name }}
          </router-link>
        </div>
      </template>
      <template #cell(slug)="row">
        <div style="width: 200px;">
          {{ row.item.slug }}
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner class="align-middle" variant="dark" type="grow" label="Loading ..">
          </b-spinner>
          Loading ..
        </div>
      </template>
    </b-table>
    <b-row class="justify-content-start ml-1">
      <b-col lg="3">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"
          @change="onClickPage" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { AuthorCreatePath, AuthorUpdatePath } from "../../router/content";
export default {
  name: "Author",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      author_search: null,
      typing: false,
      fields: [
        { key: 'No', label: 'No' },
        // { key: 'id', label: 'ID' },
        { key: 'name', label: 'Author Name' },
        { key: 'slug', label: 'Slug' },
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        // { key: 'meta', label: 'Meta' },
        { key: 'academic_title', label: ' Academic Title ' },
        {
          key: 'created',
          label: 'Created At',
          class: 'text-center text-nowrap',
          formatter: (value) => {
            return moment(value).format('DD MMM YYYY')
          }
        },
        {
          key: 'modified',
          label: 'Modified At',
          class: 'text-center text-nowrap',
          formatter: (value) => {
            return moment(value).format('DD MMM YYYY')
          }
        },
        // { key: 'action', label: 'Action' },
      ],
      AuthorCreatePath,
      AuthorUpdatePath,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.author.isLoading,
      isError: (state) => state.author.isError,
      totalRows: (state) => state.author.totalRows,
      items: (state) => state.author.authors,
    }),
  },
  mounted() {
    this.actionFetchAuthor();
  },
  methods: {
    ...mapActions('author', ['fetch', 'searchAuthor']),
    actionFetchAuthor() {
      const payload = {
        page: this.currentPage,
        limit: this.perPage,
      }
      this.fetch(payload);
    },
    actionSearchAuthor() {
      const payload = {
        page: this.currentPage,
        name: this.author_search,
        limit: this.perPage,
      }
      this.searchAuthor(payload);
    },
    onSearch(q) {
      console.log(q)
      this.author_search = q;
      this.currentPage = 1;
      if (!q) {
        this.actionFetchAuthor();
        clearTimeout(this.debounce);
        return;
      }
      this.typing = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = false;
        this.actionSearchAuthor();
      }, 600);
    },
    reloadPage() {
      if (!this.author_search) this.actionFetchAuthor();
      else this.actionSearchAuthor();
    },
    onClickPage(page) {
      this.currentPage = page;
      this.reloadPage();
    },
  },
  filters: {
    toLocalStringID(data = 0) {
      return data.toLocaleString('id');
    }
  }
};
</script>

<style scoped>

</style>
